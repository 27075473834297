import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Box, Typography, Link, Card } from "@mui/material"
import Seo from "../../components/seo"

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes // data.markdownRemark holds your post data

  console.log(posts)
  return (
    <Container sx={{ paddingBottom: "0.25em" }}>
      <Typography variant="h1">Check out my latest blog posts</Typography>
      <Box
        display="grid"
        sx={{
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gridTemplateRows: "35em",
          gap: "2em",
        }}
      >
        {posts.map(post => {
          let link = "/blog" + post.frontmatter.slug
          return (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "0.5em",
                marginBlock: "0.75em",
                height: { xs: "23em", sm: "27em", md: "28em" },
              }}
            >
              <GatsbyImage
                style={{ marginTop: "0.75em" }}
                image={
                  post.frontmatter.smallImage.childImageSharp.gatsbyImageData
                }
              ></GatsbyImage>
              <Link href={link} sx={{ paddingInline: "0.5em" }}>
                <Typography sx={{ textAlign: "center" }} variant="h2">
                  {post.frontmatter.title}
                </Typography>
              </Link>
              <Typography sx={{ textAlign: "center" }} variant="body2">
                {post.frontmatter.date}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="body1">
                By {post.frontmatter.author}
              </Typography>
            </Card>
          )
        })}
      </Box>
    </Container>
  )
}

export const markdownQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          slug
          title
          author
          smallImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`

export default Blog

export function Head() {
  return (
    <Seo
      title="Latest blog posts by Slick Blue Dragon"
      description="My latest posts for tips and ideas for your website. Is your website up to scratch and what are you going to do about it?"
    />
  )
}
